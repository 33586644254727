<template>
  <div class="container">
    <table-page dataName="car_areas"
                ref="table"
                @on-success="fetchSuccess"
                :params="{type:'CAR',activity_id:$route.query.id}"
                :request="fetchCarNets">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="$refs.setCarNet.showModel($route.query,area)">添加网点</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportData">导出活动</el-button>
      </template>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="网点名称">
        <template slot-scope="scope">
          {{`${scope.row.province || ''}${scope.row.city || ''}${scope.row.district || ''}${scope.row.net || ''}`}}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.is_open">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>启用
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>未启用
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="join_total"
                       show-overflow-tooltip
                       label="参与数">
      </el-table-column>
      <el-table-column prop="verify_total"
                       show-overflow-tooltip
                       label="核销数">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作"
                       width="250px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="showQrcode(scope.row)">活动二维码</el-button>
          <el-button type="text"
                     @click="$refs.customers.showTable(scope.row,$route.query.id)">客户管理</el-button>
          <el-button type="text"
                     v-permission="['ADMIN']"
                     @click="$refs.setCarNet.showModel($route.query,area,scope.row)">编辑</el-button>
          <el-button type="text"
                     v-permission="['ADMIN']"
                     @click="detCarNet(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <Customers ref="customers" />
    <SetCarNet ref="setCarNet"
               :disabled="disabled"
               @success="$refs.table.fetch()" />
    <Qrcode :url="qrcodeUrl"
            ref="qrcode" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import Customers from './components/Customers'
import SetCarNet from './components/SetCarNet'
import Qrcode from '@/components/Qrcode'
import { fetchCarNets, detCarNet, getActivity } from '@/api/market'
export default {
  name: 'CarNets',
  data () {
    return {
      fetchCarNets,
      qrcodeUrl: '',
      disabled: {},
      area: {},
      exportUrl: ''
    }
  },
  components: {
    TablePage,
    Customers,
    SetCarNet,
    Qrcode
  },
  methods: {
    exportData () {
      window.open(this.exportUrl, '_blank')
    },
    showQrcode (row) {
      this.qrcodeUrl = row.web_url
      this.$refs.qrcode.getQRCode()
    },
    setTerrace (row) {
      this.$refs.form.showModel(row)
    },
    detCarNet (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await detCarNet({
          activity_id: this.$route.query.id,
          car_area_ids: [id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const { activity } = res.data
        if (activity.list_net) {
          this.disabled = 2
        } else if (activity.list_district) {
          this.disabled = 2
        } else if (activity.list_city) {
          this.disabled = 1
        } else if (activity.list_province) {
          this.disabled = 3
        } else {
          this.disabled = null
        }
        this.area = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
      }
    },
    fetchSuccess (e) {
      this.exportUrl = e.customer_export_url
    }
  },
  mounted () {
    this.getActivity(this.$route.query.id)
  }
}
</script>

<style lang="scss" scoped>
</style>
